<template>
<span class="add-school-year-button" v-if="hasPerm('schools.delete_school')">
    <a href @click.prevent="showModal" class="btn-danger btn btn-sm">
      <i class="fas fa-trash"></i>
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Supprimer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-trash"></i> Supprimer une école</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <div><b>L'école "{{ school.name }}" va être supprimée</b></div><br />
          <div>Ses classes seront aussi supprimées. Les enfants ne seront plus associées aux classes</div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'

export default {
  name: 'DeleteSchoolModal',
  mixins: [BackendMixin],
  props: {
    school: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-add-adhesion-type-button' + this.randomId
    },
    async onSave() {
      let url = '/api/school/delete-school/' + this.school.id + '/'
      const backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('L\'école à été supprimée')
        this.$bvModal.hide(this.getId())
        this.$emit('done')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.delete{
  padding: 5px;
}
.school-badge{
  display: inline;
  border: 1px grey solid;
  border-radius: 5px;
  padding: 2px;
  margin: 2px;
  background-color: aliceblue;
}
</style>
