<template>
<span class="delete-school-class-button" v-if="hasPerm('schools.delete_schoolclass')">
    <a href @click.prevent="showModal" class="btn btn-sm btn-danger">
      <i class="fas fa-trash"></i>
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Supprimer"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-trash"></i> Supprimer une classe</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <div><b>La classe "{{ schoolClass.name }} {{ school.name }}" va être supprimée</b></div>
          <div>
            <div class="mt15">
              Les enfants ne seront plus associés à cette classe.
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'

export default {
  name: 'DeleteSchoolClassModal',
  mixins: [BackendMixin],
  props: {
    schoolClass: Object,
    school: Object,
  },
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-add-adhesion-type-button' + this.randomId
    },
    async onSave() {
      let url = '/api/school/delete-school-class/' + this.schoolClass.id + '/'
      const backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('La classe à été supprimée')
        this.$bvModal.hide(this.getId())
        this.$emit('done', { 'selectedSchool': this.school, })
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped>
.mt15{
  margin-top: 15px;
}
</style>
