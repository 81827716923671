<template>
  <span class="edit-school-class-button" v-if="canView">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      :ok-disabled="formInvalid"
      cancel-title="Annuler"
      :ok-title="schoolClass ? 'Modifier': 'Ajouter'"
    >
      <template v-slot:modal-title>
        <b v-if="school">
          <i class="fa fa-school"></i>&nbsp;
          <span v-if="!schoolClass">Ajouter une classe pour : {{ school.name }}</span>
          <span v-else>Modifier {{ school.name }} {{ schoolClass.name }}</span>
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row v-if="school">
        <b-col>
          <b-form-group
            id="school-group"
            label="École"
            label-for="school"
            description=""
          >
            <b-input
              :value="school.name"
              readonly
              id="school"
            >
            </b-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="name-group"
            label="Nom de la classe"
            label-for="name"
          >
            <b-form-input
              id="name"
              type="text"
              v-model="className"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="teacher-group"
            label="Enseignant"
            label-for="teacher"
          >
            <b-form-input
              id="teacher"
              type="text"
              v-model="teacherName"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <color-select
            :background="backgroundColor"
            :color="textColor"
            @selected="colorChanged"
          ></color-select>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import ColorSelect from '@/components/Controls/ColorSelect.vue'

export default {
  name: 'EditSchoolClassModal',
  components: { ColorSelect, },
  mixins: [BackendMixin],
  props: {
    school: Object,
    modalId: {
      type: String,
      default: 'bv-modal-edit-school-class',
    },
    schoolClass: Object,
  },
  data() {
    return {
      errorText: '',
      className: '',
      teacherName: '',
      backgroundColor: '',
      textColor: '',
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    formInvalid() {
      return !this.className
    },
    canView() {
      return this.hasOnePerm(['schools.add_schoolclass', 'schools.change_schoolclass'])
    },
  },
  watch: {
    schoolClass: function() {
      this.init()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.schoolClass) {
        this.className = this.schoolClass.name
        this.teacherName = this.schoolClass.teacher
        this.backgroundColor = this.schoolClass.backgroundColor
        this.textColor = this.schoolClass.textColor
      } else {
        this.className = ''
        this.teacherName = ''
        this.backgroundColor = ''
        this.textColor = ''
      }
    },
    async onSave() {
      let backendApi, message
      try {
        const data = {
          name: this.className,
          teacher: this.teacherName,
          'background_color': this.backgroundColor,
          'text_color': this.textColor,
        }
        if (!this.schoolClass) {
          let url = '/api/school/add-school-class/'
          backendApi = new BackendApi('post', url)
          data.school = this.school.id
          message = 'La classe à été ajoutée'
        } else {
          let url = '/api/school/change-school-class/' + this.schoolClass.id + '/'
          backendApi = new BackendApi('patch', url)
          message = 'La classe à été modifiée'
        }
        await backendApi.callApi(data)
        await this.addSuccess(message)
        this.$bvModal.hide(this.modalId)
        this.$emit('done', {})
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
    colorChanged(event) {
      this.backgroundColor = event.color.background
      this.textColor = event.color.text
    },
  },
}
</script>

<style scoped>

</style>
