<template>
<span class="add-school-button" v-if="hasPerm('schools.add_school')">
  <a href @click.prevent="showModal" class="btn btn-secondary btn-sm">
    <i class="fa fa-plus"></i>
  </a>
  <b-modal
    dialog-class="modal-lg"
    :id="getId()"
    @ok.prevent="onSave"
    ok-variant="primary"
    :ok-disabled="formInvalid"
    cancel-title="Annuler"
    ok-title="Ajouter"
  >
    <template v-slot:modal-title>
      <b><i class="fa fa-school"></i> Ajouter une école</b>
    </template>
    <div v-if="errorText" class="error-text">
      <i class="fa fa-error"></i> {{ errorText }}
    </div>
    <b-row>
      <b-col>
        <b-form-group
        id="name-group"
        label="Nom de l'école"
        label-for="name"
        >
          <b-form-input
          id="name"
          type="text"
          v-model="schoolName"
        ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
          <b-form-group
            id="city-group"
            label="Ville"
            label-for="city"
            description=""
          >
              <b-select
                v-model="cityName"
                :required="addressRequired"
              >
                <b-select-option
                  v-for="city of cities"
                  :key="city.id"
                  :value="city"
                >
                  {{ city.name }}
                </b-select-option>
              </b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { mapActions } from 'vuex'
import { BackendApi } from '@/utils/http'
import { makeCity } from '@/types/people'

export default {
  name: 'AddSchoolModal',
  mixins: [BackendMixin],
  data() {
    return {
      randomId: getRandomId(),
      errorText: '',
      schoolName: '',
      cities: [],
      cityNames: [],
      cityName: '',
      addressRequired: true,
    }
  },
  mounted() {
    this.loadCities()
  },
  computed: {
    formInvalid() {
      return (this.cityName.length === 0)
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-add-school-button' + this.randomId
    },
    async onSave() {
      let url = '/api/school/add-school/'
      const backendApi = new BackendApi('post', url)
      try {
        const data = {
          'name': this.schoolName,
          'city': this.cityName.id,
        }
        await backendApi.callApi(data)
        await this.addSuccess('L\'école à été ajoutée')
        this.$bvModal.hide(this.getId())
        this.$emit('done')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadCities() {
      let backendApi = new BackendApi('get', '/api/people/cities/')
      this.startLoading('entity')
      try {
        let resp = await backendApi.callApi()
        this.cities = resp.data.map(elt => makeCity(elt))
        this.cityNames = this.cities.map(elt => elt.name)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading('entity')
    },
  },
}
</script>

<style scoped>

</style>
